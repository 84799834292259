import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildLoadProjectUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { Project } from '../types';

export const loadProject = (projectId: string): Observable<Project> => {
    const obs = sendRetrieveRequest(buildLoadProjectUrl(projectId));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<Project>) => {
            const result = convertToCamel<Project>(response);
            return observableOf(result);
        }),
    );
};
